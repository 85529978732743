<template>
  <div>
    <!-- 订单 -->
    <div class="order-title" @click="showOrderList = true">
      <div>
        <svg-icon icon-class="wodedingdan" class="icon-order"></svg-icon>
        {{ currentOrder.title }}
        <svg-icon icon-class="jiantouarrow486"></svg-icon>
      </div>
      <div class="order-title-btn">点击切换套餐</div>
    </div>
    <!-- 订单列表 -->
    <el-dialog title="更换套餐订单" v-model="showOrderList" width="700px">
      <div class="order-list" style="padding-bottom: 2rem">
        <div
          class="order-list-item"
          @click="doChangeOrder(item)"
          v-for="(item, index) in orderList"
          :key="index"
        >
          <svg-icon icon-class="wodedingdan" class="icon-order"></svg-icon>
          {{ item.title }}
        </div>
      </div>
    </el-dialog>
    <!-- 试卷 -->
    <div class="paper">
      <!-- 科目 -->
      <div class="paper-subject" v-if="currentOrder.type == 1">
        <div
          :class="
            'paper-subject-item' +
            (currentPaperSubject.course.id == item.course.id ? ' active' : '')
          "
          v-for="(item, index) in paperList"
          :key="index"
          @click="doChangeSubject(item)"
        >
          {{ item.course.title }}
        </div>
      </div>
      <!-- 试卷类型 -->
      <el-tabs class="paper-list" v-if="currentPaperSubject.exam && currentPaperSubject.exam.length">
        <el-tab-pane
          :label="type.type"
          v-for="type in currentPaperSubject.exam"
          :key="type.type"
        >
          <div v-if="type.test_paper && type.test_paper.length > 0">
            <el-collapse
              v-if="type.type == '章节练习'"
              v-model="activeChapterList"
            >
              <el-collapse-item
                v-for="(paper, index) in type.test_paper"
                :key="index"
                :name="index"
              >
                <template #title>
                  <div class="paper-list-chapter-title">{{ paper.title }}</div>
                </template>
                <div class="paper-list-chapter-list">
                  <div
                    class="paper-list-item"
                    v-for="test in paper.test"
                    :key="test.id"
                    @click="goPaperQuestion(test)"
                  >
                    <span>
                      <svg-icon icon-class="icon_tikubianxie-01"></svg-icon>
                      {{ test.title }}
                    </span>
                    <el-button :type="$config.buttonType" size="mini"
                      >开始做题</el-button
                    >
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
            <div
              v-else
              class="paper-list-item"
              v-for="paper in type.test_paper"
              :key="paper.id"
              @click="goPaperQuestion(paper)"
            >
              <span>
                <svg-icon icon-class="icon_tikubianxie-01"></svg-icon>
                {{ paper.title }}
              </span>
              <el-button :type="$config.buttonType" size="mini"
                >开始做题</el-button
              >
            </div>
          </div>
          <div v-else>
            <el-empty description="暂无试卷"></el-empty>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-empty v-else description="暂无试卷"></el-empty>
    </div>
  </div>
</template>

<script type="text/javascript">
import SvgIcon from "@/components/svgicon";
import { parseTime } from "@/utils/index";
import { myOrderAll } from "@/api/user";
import { getOrderPaper, getComboPaperList, getTestGoodPaper } from "@/api/exam";
import { setSessionStorage, getSessionStorage } from "@/utils/auth";

export default {
  components: {
    SvgIcon,
  },
  data() {
    return {
      // 订单列表
      orderList: [],
      // 当前打开的订单
      currentOrder: {},
      // 订单列表展示
      showOrderList: false,
      // 订单下的试卷
      paperList: [],
      // 当前显示的科目及试卷
      currentPaperSubject: {},
      // 展开的章节练习章节
      activeChapterList: [],
    };
  },
  created() {
    // 获取已支付的套餐订单
    myOrderAll().then((res) => {
      if (res.code == 1) {
        res.data.forEach(item => {
          item.forEach(order => {
            if (order.type != 2) {
              this.orderList.push(order);
            }
          })
        })
        if (this.orderList.length > 0) {
          this.currentOrder = this.orderList[0]
          this.getPaperList(this.currentOrder)
        }
      }
    });
  },
  methods: {
    // 根据订单id，获取试卷
    getPaperList(order) {
      if (order.type == 1) {
        getOrderPaper({
          order_id: order.id,
          type: 1,
        }).then((res) => {
          if (res.code == 1) {
            this.paperList = [];
            // 过滤不需要的试卷类型
            res.data.forEach((item) => {
              let examList = [];
              item.exam.forEach((exam, index) => {
                examList.push(exam);
              });
              item.exam = examList;
              this.paperList.push(item);
            });
            this.currentPaperSubject = this.paperList[0];
          }
        });
      } else {
        getTestGoodPaper({
          goods_id: order.product_id,
        }).then((res) => {
          if (res.code == 1) {
            this.currentPaperSubject = {
              exam: [
                {
                  type: "密押试卷",
                  test_paper: res.data.exam,
                },
              ],
            };
          }
        });
      }
    },
    // 更换订单
    doChangeOrder(order) {
      this.showOrderList = false;
      this.currentOrder = order;
      this.getPaperList(order);
    },
    // 更换试卷科目
    doChangeSubject(subject) {
      this.currentPaperSubject = subject;
    },
    // 做题
    goPaperQuestion(paper) {
      if (paper.question_num == 0) {
        this.$message("当前试卷暂无试题");
        return;
      }
      // 试卷基本信息
      setSessionStorage("currentPaper", paper);
      window.open("/question");
    },
    // 格式化时间
    parseTime(time) {
      return time ? parseTime(time) : "暂无";
    },

  },
};
</script>

<style scoped lang="scss">
.order-title {
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  background-color: $background-color;
  border: 1px solid #eee;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .order-title-btn {
    color: $theme-color;
    height: 26px;
    line-height: 26px;
    font-size: 16px;
    text-align: center;
    border: 1px solid $theme-color;
    border-radius: 5px;
    padding: 0 15px;
  }
}

.order-title:hover {
  color: $theme-color;

  .order-title-btn {
    color: #fff;
    background-color: $theme-color;
  }
}

.icon-order {
  font-size: 20px;
}

.order-list {
  min-height: 150px;
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;

  .order-list-item {
    width: 600px;
    font-size: 14px;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    overflow: hidden;
    margin: 5px 20px;
  }

  .order-list-item:hover {
    color: $theme-color;
  }
}

.paper {
  background-color: $background-color;
  border: 1px solid #eee;
  padding: 10px;
  margin-top: 10px;

  .paper-subject {
    border-bottom: 1px solid #eee;
    padding-top: 10px;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;

    .paper-subject-item {
      font-size: 16px;
      margin-right: 15px;
    }

    .paper-subject-item:hover {
      color: $theme-color;
    }

    .paper-subject-item.active {
      color: $theme-color;
    }
  }

  .paper-list {
    min-height: 300px;

    .paper-list-item {
      font-size: 14px;
      margin: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .svg-icon {
        font-size: 18px;
      }

      .el-button {
        padding: 0px 15px;
      }
    }

    .paper-list-item:hover {
      color: $theme-color;
    }

    .paper-list-chapter-title {
      font-size: 14px;
      padding-left: 10px;
    }

    .paper-list-chapter-list {
      background-color: #f1f1f1;
      padding: 10px;
    }
  }
}
</style>
